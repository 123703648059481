import React, { lazy } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
// import { isEmpty } from "./helpers/common";
import { trackPageView } from "./utils/tracking";
import { isAuthenticated } from "./helpers/localstorage";
import socket from "./services/socket.service";
import Loader from "./components/_loader";
import Layout from "./views/layout";
import ThankYouPage from './views/thank-you.js';
// import NotFound from "./views/404";
// import Unsubscribe from "./views/app/unsubscribe";
// import PrelineAgency from "./views/preline.js";
// import PollPage from "./views/poll-page.js";
// import MultiStepPollPage from './views/multi-polls.js';
// import FAQs from './views/faqs.js';
// import PricingPage from './views/pricing.js';
// import PrivacyPolicy from './views/privacy.js';
// import FeaturesPage from './views/features.js';
// import Admin from './views/admin.js';

// const Admin = React.lazy(() => import("./views/admin.js"));

// Lazy-loaded components
const NotFound = lazy(() => import("./views/404"));
const Unsubscribe = lazy(() => import("./views/app/unsubscribe"));
const PrelineAgency = lazy(() => import("./views/preline.js"));
const PollPage = lazy(() => import("./views/poll-page.js"));
const MultiStepPollPage = lazy(() => import("./views/multi-polls.js"));

const EmailVerification = React.lazy(() =>
  import("./views/creator/auth/email_verification.js")
);
const Login = React.lazy(() => import("./views/creator/auth/login"));
const Register = React.lazy(() => import("./views/creator/auth/register"));
const ForgetPassword = React.lazy(() =>
  import("./views/creator/auth/forget_password")
);
const ResetPassword = React.lazy(() =>
  import("./views/creator/auth/reset_password")
);
const CreatorLayout = React.lazy(() => import("./views/creator/layout.js"));
// const CreatePoll = React.lazy(() => import("./views/poll/create_poll"));
const ViewPoll = React.lazy(() => import("./views/poll/view_poll"));
const PollResult = React.lazy(() => import("./views/poll/poll_result.js"));
// const Chat = React.lazy(() => import("./views/chat.js"));
const AdminLogin = React.lazy(() => import("./views/admin/auth/login"));
const AdminLayout = React.lazy(() => import("./views/admin/layout"));
// const PollLayout = React.lazy(() => import("./views/guides/layout.js"));

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    if (isAuthenticated()) {
      setIsAuth(true);
      setAuth({
        user: isAuthenticated(),
      });
    }
    setLoading(false);
  }, [location]);

  useEffect(() => {
    if (isAuth) {
      setIsAuth(true);
      setAuth({
        user: isAuthenticated(),
      });
    }
    setLoading(false);
  }, [isAuth]);

  useEffect(() => {
    if (socket.readyState === WebSocket.OPEN) {
      console.log("WebSocket connection is already open.");
    } else {
      socket.connect();
      console.log("WebSocket connection ");
    }

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("/admin")) {
      trackPageView({ url: location.pathname, referrer: document.referrer });
    }
  }, [location]);

  return (
    <>
      <ToastContainer />
      <Suspense fallback={<Loader />}>
        {!loading && (
          <Routes>
            {/* Non-authenticated Routes */}
            {!isAuth && (
              <>
                <Route
                  path="/login"
                  element={<Login setIsAuth={setIsAuth} />}
                />
                <Route path="/register" element={<Register />} />
                <Route path="/verify/:token" element={<EmailVerification />} />
                <Route path="/forgot-password" element={<ForgetPassword />} />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                <Route
                  exact
                  path="/admin/loginxyz"
                  element={
                    <AdminLogin setIsAuth={setIsAuth} setAuth={setAuth} />
                  }
                />
                <Route exact path="/poll/:pollId" element={<ViewPoll />} />
                <Route exact path="/results/:pollId" element={<PollResult />} />

                <Route path="/404" element={<NotFound />} />
                <Route exact path="*" element={<Layout />} />
              </>
            )}

            {/* Authenticated Routes */}
            {isAuth && auth && auth.user && (
              <>
                {auth.user.role === "creator" && (
                  <Route path="/creator/*" element={<CreatorLayout />} />
                )}
                {auth.user.role === "admin" && (
                  <Route exact path="/admin/*" element={<AdminLayout />} />
                )}
                <Route exact path="/poll/:pollId" element={<ViewPoll />} />
                <Route exact path="/results/:pollId" element={<PollResult />} />
                <Route path="/404" element={<NotFound />} />
              </>
            )}

            {/* <Route exact path="/" element={<PrelineAgency />} /> */}
            <Route exact path="/poll-page-single" element={<PollPage />} />
            <Route
              exact
              path="/poll-page-multiple"
              element={<MultiStepPollPage />}
            />
            <Route exact path="/unsubscribe" element={<Unsubscribe />} />

            {/* <Route path="*" element={<NotFound />} /> */}

            <Route exact path="/thank-you" element={<ThankYouPage />} />

            <Route exact path="*" element={<Layout />} />
          </Routes>
        )}
      </Suspense>

      {/* <BackToTopButton /> */}
    </>
  );
}

export default App;
