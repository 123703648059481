import React from "react";

import PageDetails from "../components/_page_details";

const ContactUs = () => {

  return (
    <>
      <PageDetails
        title="Contact Us - PollSage - Create polls in seconds"
        description="Pollsage is a free online polling platform that allows you to create polls and share them with your audience."
      />
      <section className="bg-neutral-900 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 xl:px-0">
          {/* Title Section */}
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h2 className="text-4xl font-bold text-[#ff0] mb-4">
              Get In Touch
            </h2>
            <p className="text-xl text-neutral-400">
              Have questions or want to learn more about PollSage? Reach out to
              our team.
            </p>
          </div>

          {/* Main Content Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-16">
            {/* Contact Form Section */}
            <div className="bg-neutral-800 rounded-xl p-8 md:p-10 border border-neutral-700 shadow-lg">
              <h3 className="text-2xl font-bold mb-6">Send us a message</h3>

              <form className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-neutral-300 mb-2"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="w-full px-4 py-3 bg-neutral-700 border border-neutral-600 rounded-lg text-white placeholder-neutral-500 focus:outline-none focus:ring-2 focus:ring-[#ff0] focus:border-transparent"
                      placeholder="Your name"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-neutral-300 mb-2"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="w-full px-4 py-3 bg-neutral-700 border border-neutral-600 rounded-lg text-white placeholder-neutral-500 focus:outline-none focus:ring-2 focus:ring-[#ff0] focus:border-transparent"
                      placeholder="your@email.com"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-neutral-300 mb-2"
                  >
                    Company
                  </label>
                  <input
                    type="text"
                    id="company"
                    className="w-full px-4 py-3 bg-neutral-700 border border-neutral-600 rounded-lg text-white placeholder-neutral-500 focus:outline-none focus:ring-2 focus:ring-[#ff0] focus:border-transparent"
                    placeholder="Your company"
                  />
                </div>

                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-neutral-300 mb-2"
                  >
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    className="w-full px-4 py-3 bg-neutral-700 border border-neutral-600 rounded-lg text-white placeholder-neutral-500 focus:outline-none focus:ring-2 focus:ring-[#ff0] focus:border-transparent"
                    placeholder="+1 (___) ___-____"
                  />
                </div>

                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-neutral-300 mb-2"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    className="w-full px-4 py-3 bg-neutral-700 border border-neutral-600 rounded-lg text-white placeholder-neutral-500 focus:outline-none focus:ring-2 focus:ring-[#ff0] focus:border-transparent"
                    placeholder="Tell us about your project or inquiry"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="w-full py-3 px-6 bg-[#ff0] hover:bg-[#ff0]/90 text-neutral-900 font-bold rounded-lg transition-all focus:outline-none focus:ring-2 focus:ring-[#ff0] focus:ring-offset-2 focus:ring-offset-neutral-800"
                >
                  Send Message
                  <svg
                    className="w-4 h-4 ml-2 inline"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    ></path>
                  </svg>
                </button>
              </form>
            </div>

            {/* Contact Information Section */}
            <div className="space-y-10">
              {/* Address Card */}
              <div className="bg-neutral-800 p-6 rounded-xl border-l-4 border-[#ff0]">
                <div className="flex items-start">
                  <div className="bg-neutral-700 p-3 rounded-lg mr-4">
                    <svg
                      className="h-6 w-6 text-[#ff0]"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      ></path>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Our Office</h3>
                    <address className="text-neutral-400 not-italic">
                      300 Bath Street, Tay House
                      <br />
                      Glasgow G2 4JR
                      <br />
                      United Kingdom
                    </address>
                  </div>
                </div>
              </div>

              {/* Email Card */}
              <div className="bg-neutral-800 p-6 rounded-xl border-l-4 border-[#ff0]">
                <div className="flex items-start">
                  <div className="bg-neutral-700 p-3 rounded-lg mr-4">
                    <svg
                      className="h-6 w-6 text-[#ff0]"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Email Us</h3>
                    <div className="space-y-2">
                      <div>
                        <p className="text-sm text-neutral-300">
                          General inquiries
                        </p>
                        <a
                          href="mailto:contact@pollsage.in"
                          className="text-[#ff0] hover:underline"
                        >
                          contact@pollsage.in
                        </a>
                      </div>
                      <div>
                        <p className="text-sm text-neutral-300">Support</p>
                        <a
                          href="mailto:support@pollsage.in"
                          className="text-[#ff0] hover:underline"
                        >
                          support@pollsage.in
                        </a>
                      </div>
                      <div>
                        <p className="text-sm text-neutral-300">Sales</p>
                        <a
                          href="mailto:sales@pollsage.in"
                          className="text-[#ff0] hover:underline"
                        >
                          sales@pollsage.in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Careers Card */}
              <div className="bg-neutral-800 p-6 rounded-xl border-l-4 border-[#ff0]">
                <div className="flex items-start">
                  <div className="bg-neutral-700 p-3 rounded-lg mr-4">
                    <svg
                      className="h-6 w-6 text-[#ff0]"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      ></path>
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Join Our Team</h3>
                    <p className="text-neutral-400 mb-4">
                      We're always looking for talented individuals to join our
                      growing team.
                    </p>
                    <a
                      href="#"
                      className="inline-flex items-center text-[#ff0] hover:underline font-medium"
                    >
                      View open positions
                      <svg
                        className="w-4 h-4 ml-1"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
