import { Link } from "react-router-dom";

const BlogsPage = () => {
  // Sample blog data - replace with your actual data from API or CMS
  
    const blogPosts = [
      {
        id: 1,
        title: "Best Free Online Poll Tools in 2024 – PollSage Leads the Way",
        excerpt:
          "Looking for the best free online poll tools to gather instant feedback, engage your audience, or make data-driven decisions?",
        date: "May 15, 2023",
        readTime: "5 min read",
        category: "Polling Tips",
      },

      {
        id: 2,
        title: "QR Code Polling: The Future of Instant Feedback",
        excerpt:
          "How QR code technology is revolutionizing real-time audience engagement.",
        date: "May 22, 2023",
        readTime: "6 min read",
        category: "Technology",
      },
      {
        id: 3,
        title: "Analyzing Poll Results for Actionable Insights",
        excerpt: "Turn your survey data into meaningful business decisions.",
        date: "May 29, 2023",
        readTime: "7 min read",
        category: "Analytics",
      },
      {
        id: 4,
        title: "Boosting Response Rates in 2023",
        excerpt: "Proven strategies to increase participation in your surveys.",
        date: "June 5, 2023",
        readTime: "4 min read",
        category: "Best Practices",
      },
      {
        id: 5,
        title: "Mobile-Friendly Poll Design Essentials",
        excerpt: "Creating surveys that work perfectly on all devices.",
        date: "June 12, 2023",
        readTime: "5 min read",
        category: "Design",
      },
      {
        id: 6,
        title: "The Psychology of Effective Question Writing",
        excerpt: "How phrasing affects response quality and quantity.",
        date: "June 19, 2023",
        readTime: "8 min read",
        category: "Psychology",
      },
      {
        id: 7,
        title: "Event Planning with Live Polling",
        excerpt: "Engage your audience during conferences and meetings.",
        date: "June 26, 2023",
        readTime: "6 min read",
        category: "Events",
      },
      {
        id: 8,
        title: "Accessibility in Online Surveys",
        excerpt: "Making your polls inclusive for all users.",
        date: "July 3, 2023",
        readTime: "7 min read",
        category: "Inclusivity",
      },
      {
        id: 9,
        title: "Comparing Polling Platforms: 2023 Review",
        excerpt: "Feature breakdown of top survey tools this year.",
        date: "July 10, 2023",
        readTime: "9 min read",
        category: "Technology",
      },
      {
        id: 10,
        title: "Gamification Techniques for Surveys",
        excerpt: "Increase engagement through playful elements.",
        date: "July 17, 2023",
        readTime: "5 min read",
        category: "Engagement",
      },
      {
        id: 11,
        title: "Data Visualization for Survey Results",
        excerpt: "Presenting your findings in compelling ways.",
        date: "July 24, 2023",
        readTime: "6 min read",
        category: "Analytics",
      },
      {
        id: 12,
        title: "The Ethics of Data Collection",
        excerpt: "Responsible practices for gathering audience insights.",
        date: "July 31, 2023",
        readTime: "7 min read",
        category: "Ethics",
      },
      {
        id: 13,
        title: "Seasonal Survey Trends to Watch",
        excerpt: "How timing affects response patterns throughout the year.",
        date: "August 7, 2023",
        readTime: "5 min read",
        category: "Trends",
      },
      {
        id: 14,
        title: "Integrating Polls with Marketing Campaigns",
        excerpt: "Using surveys to boost your marketing effectiveness.",
        date: "August 14, 2023",
        readTime: "6 min read",
        category: "Marketing",
      },
      {
        id: 15,
        title: "Microsurveys: Less is More",
        excerpt: "When shorter polls deliver better results.",
        date: "August 21, 2023",
        readTime: "4 min read",
        category: "Best Practices",
      },
      {
        id: 16,
        title: "Multilingual Survey Strategies",
        excerpt: "Reaching global audiences effectively.",
        date: "August 28, 2023",
        readTime: "7 min read",
        category: "Global",
      },
      {
        id: 17,
        title: "The Science of Survey Timing",
        excerpt: "When to send polls for optimal response rates.",
        date: "September 4, 2023",
        readTime: "5 min read",
        category: "Psychology",
      },
      {
        id: 18,
        title: "Automating Survey Workflows",
        excerpt: "Time-saving techniques for frequent pollsters.",
        date: "September 11, 2023",
        readTime: "6 min read",
        category: "Productivity",
      },
      {
        id: 19,
        title: "Survey Templates That Convert",
        excerpt: "Ready-to-use frameworks for common use cases.",
        date: "September 18, 2023",
        readTime: "5 min read",
        category: "Templates",
      },
      {
        id: 20,
        title: "The Future of AI in Polling",
        excerpt: "How machine learning is transforming surveys.",
        date: "September 25, 2023",
        readTime: "8 min read",
        category: "Technology",
      },
      {
        id: 21,
        title: "10 Tips for Creating Engaging Polls",
        excerpt:
          "Learn how to craft polls that get maximum participation from your audience.",
        date: "May 15, 2023",
        readTime: "5 min read",
        category: "Polling Tips",
      },
    ];

  return (
    <div className="min-h-screen bg-[#121212] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-white mb-4">PollSage Blog</h1>
          <p className="text-xl text-[#a0a0a0] max-w-3xl mx-auto">
            Insights, tips, and best practices for effective polling and
            audience engagement
          </p>
        </div>

        {/* Featured Post (optional) */}
        {/* <FeaturedPost /> */}

        {/* Blog Posts Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <Link
              to={`/blogs/${post.id}`}
              key={post.id}
              className="bg-[#1e1e1e] rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 hover:border-l-4 hover:border-[#ff0]"
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-3">
                  <span className="text-xs font-semibold px-2 py-1 bg-[#2d2d2d] text-[#ff0] rounded">
                    {post.category}
                  </span>
                  <span className="text-sm text-[#a0a0a0]">{post.date}</span>
                </div>
                <h2 className="text-xl font-bold text-white mb-2">
                  {post.title}
                </h2>
                <p className="text-[#a0a0a0] mb-4">{post.excerpt}</p>
                <div className="flex justify-between items-center">
                  <span className="text-sm text-[#a0a0a0]">
                    {post.readTime}
                  </span>
                  <button className="text-[#ff0] hover:text-yellow-300 font-medium text-sm flex items-center">
                    Read more
                    <svg
                      className="w-4 h-4 ml-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* Pagination (optional) */}
        <div className="mt-12 flex justify-center">
          <nav className="flex items-center space-x-2">
            <button className="px-3 py-1 rounded-md bg-[#1e1e1e] text-[#a0a0a0] hover:bg-[#2d2d2d]">
              Previous
            </button>
            <button className="px-3 py-1 rounded-md bg-[#ff0] text-[#121212] font-medium">
              1
            </button>
            <button className="px-3 py-1 rounded-md bg-[#1e1e1e] text-[#a0a0a0] hover:bg-[#2d2d2d]">
              2
            </button>
            <button className="px-3 py-1 rounded-md bg-[#1e1e1e] text-[#a0a0a0] hover:bg-[#2d2d2d]">
              3
            </button>
            <button className="px-3 py-1 rounded-md bg-[#1e1e1e] text-[#a0a0a0] hover:bg-[#2d2d2d]">
              Next
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default BlogsPage;
