import React, { useEffect, useState } from "react";
import links from "../utils/nav_link";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../helpers/localstorage";

const Header = ({ isAuth, auth }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(isAuthenticated());
  }, []);

  console.log(user);

  const [mobileMenuHidden, setMobileMenuHidden] = useState(false);

  const url = window.location.pathname;

  return (
    <header class="sticky top-4 inset-x-0 flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full before:absolute before:inset-0 before:max-w-7xl before:mx-2 lg:before:mx-auto before:rounded-full before:bg-neutral-800/30 before:backdrop-blur-md">
      <nav class="relative max-w-7xl w-full py-2.5 ps-5 pe-2 md:flex md:items-center md:justify-between md:py-0 mx-2 lg:mx-auto">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <Link
              class="flex-none rounded-md text-xl inline-block font-semibold focus:outline-hidden focus:opacity-80"
              to={"/"}
              aria-label="Preline"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="60"
                viewBox="0 0 200 60"
                fill="none"
              >
                <path
                  d="M10 40V20C10 18.8954 10.8954 18 12 18H18C19.1046 18 20 18.8954 20 20V40C20 41.1046 19.1046 42 18 42H12C10.8954 42 10 41.1046 10 40Z"
                  fill="#FFD700"
                />
                <path
                  d="M30 40V15C30 13.8954 30.8954 13 32 13H38C39.1046 13 40 13.8954 40 15V40C40 41.1046 39.1046 42 38 42H32C30.8954 42 30 41.1046 30 40Z"
                  fill="#FFA500"
                />
                <path
                  d="M50 40V25C50 23.8954 50.8954 23 52 23H58C59.1046 23 60 23.8954 60 25V40C60 41.1046 59.1046 42 58 42H52C50.8954 42 50 41.1046 50 40Z"
                  fill="#FF6347"
                />

                <text
                  x="70"
                  y="40"
                  fontFamily="Arial, sans-serif"
                  fontSize="20"
                  fontWeight="bold"
                  fill="#FFFFFF"
                  style={{ letterSpacing: "2px" }}
                >
                  Pollsage
                </text>

                <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                  <feGaussianBlur
                    in="SourceAlpha"
                    stdDeviation="2"
                    result="blur"
                  />
                  <feOffset in="blur" dx="0" dy="0" result="offsetBlur" />
                  <feMerge>
                    <feMergeNode in="offsetBlur" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
                <g filter="url(#glow)">
                  <path
                    d="M10 40V20C10 18.8954 10.8954 18 12 18H18C19.1046 18 20 18.8954 20 20V40C20 41.1046 19.1046 42 18 42H12C10.8954 42 10 41.1046 10 40Z"
                    fill="#FFD700"
                  />
                  <path
                    d="M30 40V15C30 13.8954 30.8954 13 32 13H38C39.1046 13 40 13.8954 40 15V40C40 41.1046 39.1046 42 38 42H32C30.8954 42 30 41.1046 30 40Z"
                    fill="#FFA500"
                  />
                  <path
                    d="M50 40V25C50 23.8954 50.8954 23 52 23H58C59.1046 23 60 23.8954 60 25V40C60 41.1046 59.1046 42 58 42H52C50.8954 42 50 41.1046 50 40Z"
                    fill="#FF6347"
                  />
                </g>
              </svg>
            </Link>
          </div>

          <div class="md:hidden">
            <button
              type="button"
              class="hs-collapse-toggle size-8 flex justify-center items-center text-sm font-semibold rounded-full bg-neutral-800 text-white disabled:opacity-50 disabled:pointer-events-none"
              id="hs-navbar-floating-dark-collapse"
              aria-expanded="false"
              aria-controls="hs-navbar-floating-dark"
              aria-label="Toggle navigation"
              data-hs-collapse="#hs-navbar-floating-dark"
            >
              <svg
                class="hs-collapse-open:hidden shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="3" x2="21" y1="6" y2="6" />
                <line x1="3" x2="21" y1="12" y2="12" />
                <line x1="3" x2="21" y1="18" y2="18" />
              </svg>
              <svg
                class="hs-collapse-open:block hidden shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>
        </div>

        <div
          id="hs-navbar-floating-dark"
          class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block"
          aria-labelledby="hs-navbar-floating-dark-collapse"
        >
          <div class="flex flex-col md:flex-row md:items-center md:justify-end gap-y-3 py-2 md:py-0 md:ps-7">
            <Link
              class="pe-3 ps-px sm:px-3 md:py-4 text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
              to={"/"}
              aria-current="page"
            >
              Home
            </Link>

            <Link
              class="pe-3 ps-px sm:px-3 md:py-4 text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
              to={"/features"}
            >
              Features
            </Link>

            <Link
              class="pe-3 ps-px sm:px-3 md:py-4 text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
              to={"/pricing"}
            >
              Pricing
            </Link>

            <div class="hs-dropdown [--strategy:static] md:[--strategy:fixed] [--adaptive:none] md:[--adaptive:adaptive] [--is-collapse:true] md:[--is-collapse:false] pe-3 ps-px sm:px-3 md:py-4">
              <button
                id="hs-dropdown-floating-dark"
                type="button"
                class="hs-dropdown-toggle flex items-center w-full text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
                aria-haspopup="menu"
                aria-expanded="false"
                aria-label="Dropdown"
              >
                Resources
                <svg
                  class="hs-dropdown-open:-rotate-180 md:hs-dropdown-open:rotate-0 duration-300 shrink-0 ms-auto md:ms-1 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </button>

              <div
                class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-48 hidden z-10 md:bg-neutral-800 md:shadow-md rounded-lg before:absolute top-full before:-top-5 before:start-0 before:w-full before:h-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="hs-dropdown-floating-dark"
              >
                <div class="md:py-1 md:px-1 mt-3 md:mt-0 flex flex-col gap-y-3 md:gap-y-0">
                  <a
                    class="flex items-center gap-x-3.5 md:py-2 md:px-3 rounded-lg text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
                    href="/blog"
                  >
                    Blog
                  </a>

                  <a
                    class="flex items-center gap-x-3.5 md:py-2 md:px-3 rounded-lg text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
                    href="/docs"
                  >
                    Documentation
                  </a>

                  <a
                    class="flex items-center gap-x-3.5 md:py-2 md:px-3 rounded-lg text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
                    href="/case-studies"
                  >
                    Case Studies
                  </a>
                </div>
              </div>
            </div>

            <div class="hs-dropdown [--strategy:static] md:[--strategy:fixed] [--adaptive:none] md:[--adaptive:adaptive] [--is-collapse:true] md:[--is-collapse:false] pe-3 ps-px sm:px-3 md:py-4">
              <button
                id="hs-dropdown-floating-dark-about"
                type="button"
                class="hs-dropdown-toggle flex items-center w-full text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
                aria-haspopup="menu"
                aria-expanded="false"
                aria-label="Dropdown"
              >
                About
                <svg
                  class="hs-dropdown-open:-rotate-180 md:hs-dropdown-open:rotate-0 duration-300 shrink-0 ms-auto md:ms-1 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m6 9 6 6 6-6" />
                </svg>
              </button>

              <div
                class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-48 hidden z-10 md:bg-neutral-800 md:shadow-md rounded-lg before:absolute top-full before:-top-5 before:start-0 before:w-full before:h-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="hs-dropdown-floating-dark-about"
              >
                <div class="md:py-1 md:px-1 mt-3 md:mt-0 flex flex-col gap-y-3 md:gap-y-0">
                  <a
                    class="flex items-center gap-x-3.5 md:py-2 md:px-3 rounded-lg text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
                    href="/about"
                  >
                    About Us
                  </a>

                  <a
                    class="flex items-center gap-x-3.5 md:py-2 md:px-3 rounded-lg text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
                    href="/careers"
                  >
                    Careers
                  </a>

                  <a
                    class="flex items-center gap-x-3.5 md:py-2 md:px-3 rounded-lg text-sm text-white hover:text-neutral-300 focus:outline-hidden focus:text-neutral-300"
                    href="/contact"
                  >
                    Contact
                  </a>
                </div>
              </div>
            </div>

            <div>
              {!user && (
                <a
                  class="group inline-flex items-center gap-x-2 py-2 px-3 bg-[#ff0] font-medium text-sm text-neutral-800 rounded-full focus:outline-hidden"
                  href="/login"
                >
                  Login
                </a>
              )}
              
              {user && user.role && (
                <Link
                  class="group inline-flex items-center gap-x-2 py-2 px-3 bg-[#ff0] font-medium text-sm text-neutral-800 rounded-full focus:outline-hidden"
                  to={user.role === "creator" ? "/creator/dashboard" : "/admin/dashboard"}
                >
                  Dashboard
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
