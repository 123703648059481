import React, { useEffect } from "react";
import PageDetails from "../components/_page_details";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center bg-neutral-900 min-h-screen">
      <PageDetails title="404 - PollSage" description="404 - PollSage" />
      <div className="flex flex-col">
        {/* Error Container */}
        <div className="flex flex-col items-center">
          {/* 404 Text */}
          <div className="text-[#ff0] font-bold text-7xl">404</div>

          {/* Main Heading */}
          <div className="font-bold text-white text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
            This page does not exist
          </div>

          {/* Subheading */}
          <div className="text-neutral-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
            The page you are looking for could not be found.
          </div>

          {/* Call-to-Action Button */}
          <a
            href="/"
            className="mt-12 inline-flex items-center gap-x-2 py-3 px-6 bg-[#ff0] font-medium text-sm text-neutral-900 rounded-full hover:bg-[#ff0]/90 focus:outline-hidden focus:ring-2 focus:ring-[#ff0] focus:ring-offset-2 transition"
          >
            Go Back Home
            <svg
              className="shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14" />
              <path d="m12 5 7 7-7 7" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
