import React from "react";

const ThankYouPage = () => {
  return (
    <div className="min-h-screen bg-[#121212] flex items-center justify-center p-5">
      <div className="bg-[#1e1e1e] rounded-2xl p-10 max-w-md w-full text-center shadow-xl">
        {/* Checkmark icon */}
        <div className="mx-auto w-16 h-16 mb-6">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-[#ff0]"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
              fill="currentColor"
            />
          </svg>
        </div>

        {/* Title */}
        <h1 className="text-white text-3xl font-bold mb-4">Thank You!</h1>

        {/* Message */}
        <p className="text-[#a0a0a0] mb-6">
          We appreciate you taking the time to complete our poll. Your feedback
          is valuable to us.
        </p>

        {/* Divider */}
        <div className="border-t border-[#2d2d2d] my-6"></div>

        {/* Additional info */}
        <div className="text-[#a0a0a0] space-y-2 mb-8">
          <p>Your responses have been recorded successfully.</p>
          <p>We'll use this information to improve our services.</p>
        </div>

        {/* Action button */}
        <button
          onClick={() => (window.location.href = "/")}
          className="bg-[#ff0] hover:bg-yellow-500 text-[#121212] font-semibold py-3 px-6 rounded-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#ff0] focus:ring-opacity-50"
        >
          Return Home
        </button>
      </div>
    </div>
  );
};

export default ThankYouPage;
