import React, { lazy } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Route, Routes } from "react-router-dom";
import ContactUs from "./contact_us";

import Terms from "./terms";
import NotFound from "./404";
import Contributors from "./app/contributors";

import GuideLayout from "../views/guides/layout.js";
import PrelineAgency from "./preline.js";
import BlogPost from "./blogs/blog1.js";
import StepByStepGuide from "./guides/guide.js";
import ThankYouPage from "./thank-you.js";
import BlogsPage from "./blogs/index.js";

const FAQs = lazy(() => import("./faqs.js"));
const PricingPage = lazy(() => import("./pricing.js"));
const PrivacyPolicy = lazy(() => import("./privacy.js"));
const FeaturesPage = lazy(() => import("./features.js"));

const GeneralLayout = ({ isAuth, auth }) => {
  return (
    <div class="bg-neutral-900">
      <Header isAuth={isAuth} auth={auth} />
      {/* Main content */}
      <main className="flex-1 flex-grow mx-auto  w-full">
        <Routes>
          <Route path="/" element={<PrelineAgency />} />
          <Route exact path="/features" element={<FeaturesPage />} />
          <Route exact path="/pricing" element={<PricingPage />} />
          <Route exact path="/faqs" element={<FAQs />} />

          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/contact-us" element={<ContactUs />} />
          {/* <Route path="/privacy" element={<Privacy />} /> */}
          <Route path="/terms" element={<Terms />} />
          <Route path="/contributors" element={<Contributors />} />

          {/* <Route exact path="/guide/" element={<GuideLayout />} /> */}
          <Route exact path="/guide" element={<StepByStepGuide />} />

          
          <Route exact path="/blogs" element={<BlogsPage />} />
          <Route exact path="/blogs/1" element={<BlogPost />} />

          
          {/* <Route path="*" element={<Navigate to={"/creator/dashboard"} />} /> */}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default GeneralLayout;
