import React, { useState } from "react";
import { logout } from "../../services/creator_auth.service";
import { successToast } from "../../utils/toaster";
import { Link, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const [toggleSb, setToggleSb] = useState(false);

  const toggleSidebar = () => {
    const sidebar = document.getElementById("sidebar-multi-level-sidebar");
    sidebar.classList.toggle("-translate-x-full");
    setToggleSb(!toggleSb);
  };

  const toggleDropdown = (event) => {
    const dropdown = event.currentTarget.nextElementSibling;
    dropdown.classList.toggle("hidden");
  };

  const handleLogout = () => {
    logout();
    successToast("Logged out successfully");
    navigate("/");
  };

  const handleSubMenu = (event) => {
    const dropdown = event.currentTarget.nextElementSibling;
    dropdown.classList.toggle("hidden");
  };

  return (
    <div className="fixed flex flex-col left-0 w-14 hover:w-64 md:w-64 bg-neutral-900 h-full text-white transition-all duration-300 border-r z-40 sidebar">
      <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
        <ul className="flex flex-col py-1 space-y-1" id="creator-sidebar__menu">
          <li className="px-5 hidden md:block border-b">
            <Link
              to="/"
              className="mx-auto text-lg font-semibold tracking-widest text-black uppercase rounded-lg focus:outline-none focus:shadow-outline"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="60"
                viewBox="0 0 200 60"
                fill="none"
              >
                <path
                  d="M10 40V20C10 18.8954 10.8954 18 12 18H18C19.1046 18 20 18.8954 20 20V40C20 41.1046 19.1046 42 18 42H12C10.8954 42 10 41.1046 10 40Z"
                  fill="#FFD700"
                />
                <path
                  d="M30 40V15C30 13.8954 30.8954 13 32 13H38C39.1046 13 40 13.8954 40 15V40C40 41.1046 39.1046 42 38 42H32C30.8954 42 30 41.1046 30 40Z"
                  fill="#FFA500"
                />
                <path
                  d="M50 40V25C50 23.8954 50.8954 23 52 23H58C59.1046 23 60 23.8954 60 25V40C60 41.1046 59.1046 42 58 42H52C50.8954 42 50 41.1046 50 40Z"
                  fill="#FF6347"
                />

                <text
                  x="70"
                  y="40"
                  fontFamily="Arial, sans-serif"
                  fontSize="20"
                  fontWeight="bold"
                  fill="#FFFFFF"
                  style={{ letterSpacing: "2px" }}
                >
                  Pollsage
                </text>

                <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                  <feGaussianBlur
                    in="SourceAlpha"
                    stdDeviation="2"
                    result="blur"
                  />
                  <feOffset in="blur" dx="0" dy="0" result="offsetBlur" />
                  <feMerge>
                    <feMergeNode in="offsetBlur" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
                <g filter="url(#glow)">
                  <path
                    d="M10 40V20C10 18.8954 10.8954 18 12 18H18C19.1046 18 20 18.8954 20 20V40C20 41.1046 19.1046 42 18 42H12C10.8954 42 10 41.1046 10 40Z"
                    fill="#FFD700"
                  />
                  <path
                    d="M30 40V15C30 13.8954 30.8954 13 32 13H38C39.1046 13 40 13.8954 40 15V40C40 41.1046 39.1046 42 38 42H32C30.8954 42 30 41.1046 30 40Z"
                    fill="#FFA500"
                  />
                  <path
                    d="M50 40V25C50 23.8954 50.8954 23 52 23H58C59.1046 23 60 23.8954 60 25V40C60 41.1046 59.1046 42 58 42H52C50.8954 42 50 41.1046 50 40Z"
                    fill="#FF6347"
                  />
                </g>
              </svg>
            </Link>
          </li>
          <li className="px-5 md:hidden">
            <Link to="/" className="flex flex-row items-center h-8">
              <img src="/logo.png" alt="" className="w-5 h-5 mx-auto" />
            </Link>
          </li>
          <li className="px-5 hidden md:block">
            <div className="flex flex-row items-center h-8">
              <div className="text-sm font-light tracking-wide text-gray-400 uppercase">
                Menu
              </div>
            </div>
          </li>
          <li>
            <Link
              to="/creator/dashboard"
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  ></path>
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Dashboard
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={"/creator/polls"}
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="8" y1="6" x2="21" y2="6" />
                  <line x1="8" y1="12" x2="21" y2="12" />
                  <line x1="8" y1="18" x2="21" y2="18" />
                  <line x1="3" y1="6" x2="3" y2="6" />
                  <line x1="3" y1="12" x2="3" y2="12" />
                  <line x1="3" y1="18" x2="3" y2="18" />
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Poll Management
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={"/creator/activity"}
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 7L13 15L9 11L3 17M21 7H15M21 7V13"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Results & Analytics
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={"/creator/polls-sharing"}
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  fill="currentColor"
                  className="w-5 h-5"
                  version="1.1"
                  viewBox="0 0 490 490"
                >
                  <path
                    d="M408.444,344.121c-26.856,0-50.352,14.601-63.012,36.271l-105.756-69.705c8.625-16.743,13.5-35.722,13.5-55.817
	c0-20.087-4.871-39.057-13.492-55.794l115.648-76.221c13.313,14.157,32.191,23.023,53.111,23.023
	c40.218,0,72.937-32.72,72.937-72.938C481.382,32.721,448.662,0,408.444,0c-40.219,0-72.939,32.721-72.939,72.939
	c0,12.114,2.986,23.539,8.234,33.604L229.1,182.099c-22.304-30.014-58.019-49.498-98.202-49.498
	c-67.425,0-122.279,54.85-122.279,122.268c0,67.429,54.854,122.285,122.279,122.285c40.178,0,75.889-19.482,98.193-49.492
	l108.621,71.594c-1.436,5.701-2.207,11.664-2.207,17.805c0,40.219,32.721,72.94,72.939,72.94c40.218,0,72.937-32.721,72.937-72.94
	C481.382,376.842,448.662,344.121,408.444,344.121z M408.444,20c29.189,0,52.937,23.748,52.937,52.939
	c0,29.19-23.748,52.938-52.937,52.938c-29.19,0-52.939-23.748-52.939-52.938C355.505,43.748,379.254,20,408.444,20z
	 M130.897,357.154c-56.397,0-102.279-45.885-102.279-102.285c0-56.391,45.882-102.268,102.279-102.268
	c56.398,0,102.279,45.878,102.279,102.268C233.177,311.27,187.295,357.154,130.897,357.154z M408.444,470
	c-29.19,0-52.939-23.749-52.939-52.94c0-29.19,23.749-52.939,52.939-52.939c29.189,0,52.937,23.749,52.937,52.939
	C461.382,446.251,437.634,470,408.444,470z"
                  />
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Poll Sharing
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={"/creator/activity"}
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  fill="currentColor"
                  className="w-5 h-5"
                  viewBox="0 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M23.313 26.102l-6.296-3.488c2.34-1.841 2.976-5.459 2.976-7.488v-4.223c0-2.796-3.715-5.91-7.447-5.91-3.73 0-7.544 3.114-7.544 5.91v4.223c0 1.845 0.78 5.576 3.144 7.472l-6.458 3.503s-1.688 0.752-1.688 1.689v2.534c0 0.933 0.757 1.689 1.688 1.689h21.625c0.931 0 1.688-0.757 1.688-1.689v-2.534c0-0.994-1.689-1.689-1.689-1.689zM23.001 30.015h-21.001v-1.788c0.143-0.105 0.344-0.226 0.502-0.298 0.047-0.021 0.094-0.044 0.139-0.070l6.459-3.503c0.589-0.32 0.979-0.912 1.039-1.579s-0.219-1.32-0.741-1.739c-1.677-1.345-2.396-4.322-2.396-5.911v-4.223c0-1.437 2.708-3.91 5.544-3.91 2.889 0 5.447 2.44 5.447 3.91v4.223c0 1.566-0.486 4.557-2.212 5.915-0.528 0.416-0.813 1.070-0.757 1.739s0.446 1.267 1.035 1.589l6.296 3.488c0.055 0.030 0.126 0.063 0.184 0.089 0.148 0.063 0.329 0.167 0.462 0.259v1.809zM30.312 21.123l-6.39-3.488c2.34-1.841 3.070-5.459 3.070-7.488v-4.223c0-2.796-3.808-5.941-7.54-5.941-2.425 0-4.904 1.319-6.347 3.007 0.823 0.051 1.73 0.052 2.514 0.302 1.054-0.821 2.386-1.308 3.833-1.308 2.889 0 5.54 2.47 5.54 3.941v4.223c0 1.566-0.58 4.557-2.305 5.915-0.529 0.416-0.813 1.070-0.757 1.739 0.056 0.67 0.445 1.267 1.035 1.589l6.39 3.488c0.055 0.030 0.126 0.063 0.184 0.089 0.148 0.063 0.329 0.167 0.462 0.259v1.779h-4.037c0.61 0.46 0.794 1.118 1.031 2h3.319c0.931 0 1.688-0.757 1.688-1.689v-2.503c-0.001-0.995-1.689-1.691-1.689-1.691z"></path>
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Audience Engagement
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={"/creator/api-integrations"}
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  fill="currentColor"
                  className="w-5 h-5"
                  viewBox="0 0 16 16"
                  id="puzzle-16px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M-8.5,16h-4a.5.5,0,0,1-.5-.5v-1A1.5,1.5,0,0,0-14.5,13,1.5,1.5,0,0,0-16,14.5v1a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5V3.5a.5.5,0,0,1,.5-.5H-17V2.5A2.5,2.5,0,0,1-14.5,0,2.5,2.5,0,0,1-12,2.5V3h3.5a.5.5,0,0,1,.5.5V7h.5A2.5,2.5,0,0,1-5,9.5,2.5,2.5,0,0,1-7.5,12H-8v3.5A.5.5,0,0,1-8.5,16ZM-12,15h3V11.5a.5.5,0,0,1,.5-.5h1A1.5,1.5,0,0,0-6,9.5,1.5,1.5,0,0,0-7.5,8h-1A.5.5,0,0,1-9,7.5V4h-3.5a.5.5,0,0,1-.5-.5v-1A1.5,1.5,0,0,0-14.5,1,1.5,1.5,0,0,0-16,2.5v1a.5.5,0,0,1-.5.5H-20V15h3v-.5A2.5,2.5,0,0,1-14.5,12,2.5,2.5,0,0,1-12,14.5Z"
                    transform="translate(21)"
                  />
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                API & Integrations
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={"/creator/activity"}
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 3V21"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 7H11"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 11H11"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 15H11"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Templates Library
              </span>
            </Link>
          </li>

          <li>
            <Link
              to="/creator/settings/account"
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Profile Settings
              </span>
            </Link>
          </li>
          {/* <li>
            <a
              onClick={(e) => handleSubMenu(e)}
              href="void:javascript()"
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Settings
              </span>
              <span className="hidden md:block px-2 py-0.5 ml-auto text-xs font-medium tracking-wide">
                <svg
                  className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 false"
                  viewBox="0 0 12 12"
                >
                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"></path>
                </svg>
              </span>
            </a>

            <ul className="hidden flex flex-col py-2 space-y-1">
              <li className="">
                <Link
                  to={"/creator/settings/account"}
                  className="relative flex flex-row items-center px-12 h-9 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
                >
                  {" "}
                  My Account
                </Link>
              </li>
              <li className="">
                <Link
                  to={"/creator/emails/template/create"}
                  className="relative flex flex-row items-center px-12 h-9 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
                >
                  {" "}
                  My Notifications
                </Link>
              </li>
              <li className="">
                <Link
                  to={"/creator/settings/feedback"}
                  className="relative flex flex-row items-center px-12 h-9 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
                >
                  {" "}
                  Give Feedback
                </Link>
              </li>
            </ul>
          </li> */}

          <li className="px-5 hidden md:block">
            <div className="flex flex-row items-center h-8">
              <div className="text-sm font-light tracking-wide text-gray-500 uppercase">
                Others
              </div>
            </div>
          </li>
          <li>
            <Link
              to="/guide/"
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 10.4V20M12 10.4C12 8.15979 12 7.03969 11.564 6.18404C11.1805 5.43139 10.5686 4.81947 9.81596 4.43597C8.96031 4 7.84021 4 5.6 4H4.6C4.03995 4 3.75992 4 3.54601 4.10899C3.35785 4.20487 3.20487 4.35785 3.10899 4.54601C3 4.75992 3 5.03995 3 5.6V16.4C3 16.9601 3 17.2401 3.10899 17.454C3.20487 17.6422 3.35785 17.7951 3.54601 17.891C3.75992 18 4.03995 18 4.6 18H7.54668C8.08687 18 8.35696 18 8.61814 18.0466C8.84995 18.0879 9.0761 18.1563 9.29191 18.2506C9.53504 18.3567 9.75977 18.5065 10.2092 18.8062L12 20M12 10.4C12 8.15979 12 7.03969 12.436 6.18404C12.8195 5.43139 13.4314 4.81947 14.184 4.43597C15.0397 4 16.1598 4 18.4 4H19.4C19.9601 4 20.2401 4 20.454 4.10899C20.6422 4.20487 20.7951 4.35785 20.891 4.54601C21 4.75992 21 5.03995 21 5.6V16.4C21 16.9601 21 17.2401 20.891 17.454C20.7951 17.6422 20.6422 17.7951 20.454 17.891C20.2401 18 19.9601 18 19.4 18H16.4533C15.9131 18 15.643 18 15.3819 18.0466C15.15 18.0879 14.9239 18.1563 14.7081 18.2506C14.465 18.3567 14.2402 18.5065 13.7908 18.8062L12 20"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Help center
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/faqs"
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
              target="_blank"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 3C7.04 3 3 7.04 3 12C3 16.96 7.04 21 12 21C16.96 21 21 16.96 21 12C21 7.04 16.96 3 12 3ZM12 19.5C7.86 19.5 4.5 16.14 4.5 12C4.5 7.86 7.86 4.5 12 4.5C16.14 4.5 19.5 7.86 19.5 12C19.5 16.14 16.14 19.5 12 19.5ZM14.3 7.7C14.91 8.31 15.25 9.13 15.25 10C15.25 10.87 14.91 11.68 14.3 12.3C13.87 12.73 13.33 13.03 12.75 13.16V13.5C12.75 13.91 12.41 14.25 12 14.25C11.59 14.25 11.25 13.91 11.25 13.5V12.5C11.25 12.09 11.59 11.75 12 11.75C12.47 11.75 12.91 11.57 13.24 11.24C13.57 10.91 13.75 10.47 13.75 10C13.75 9.53 13.57 9.09 13.24 8.76C12.58 8.1 11.43 8.1 10.77 8.76C10.44 9.09 10.26 9.53 10.26 10C10.26 10.41 9.92 10.75 9.51 10.75C9.1 10.75 8.76 10.41 8.76 10C8.76 9.13 9.1 8.32 9.71 7.7C10.94 6.47 13.08 6.47 14.31 7.7H14.3ZM13 16.25C13 16.8 12.55 17.25 12 17.25C11.45 17.25 11 16.8 11 16.25C11 15.7 11.45 15.25 12 15.25C12.55 15.25 13 15.7 13 16.25Z"
                    fill="#fff"
                  />
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">FAQs</span>
            </Link>
          </li>
          <li>
            <Link
              to="/privacy-policy"
              target="_blank"
              className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 12L11 14L15 9.99999M20 12C20 16.4611 14.54 19.6937 12.6414 20.683C12.4361 20.79 12.3334 20.8435 12.191 20.8712C12.08 20.8928 11.92 20.8928 11.809 20.8712C11.6666 20.8435 11.5639 20.79 11.3586 20.683C9.45996 19.6937 4 16.4611 4 12V8.21759C4 7.41808 4 7.01833 4.13076 6.6747C4.24627 6.37113 4.43398 6.10027 4.67766 5.88552C4.9535 5.64243 5.3278 5.50207 6.0764 5.22134L11.4382 3.21067C11.6461 3.13271 11.75 3.09373 11.857 3.07827C11.9518 3.06457 12.0482 3.06457 12.143 3.07827C12.25 3.09373 12.3539 3.13271 12.5618 3.21067L17.9236 5.22134C18.6722 5.50207 19.0465 5.64243 19.3223 5.88552C19.566 6.10027 19.7537 6.37113 19.8692 6.6747C20 7.01833 20 7.41808 20 8.21759V12Z"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Privacy
              </span>
            </Link>
          </li>
          <li>
            <button
              onClick={handleLogout}
              className="relative w-full flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
            >
              <span className="inline-flex justify-center items-center ml-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-right"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />{" "}
                </svg>
              </span>
              <span className="ml-2 text-sm tracking-wide truncate">
                Logout
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;



{/* <li>
  <ul className="hidden flex flex-col py-2 space-y-1">
    <li className="">
      <Link
        to={"/creator/emails/template/list"}
        className="relative flex flex-row items-center px-12 h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
      >
        {" "}
        Email templates
      </Link>
    </li>
    <li className="">
      <Link
        to={"/creator/emails/template/create"}
        className="relative flex flex-row items-center px-12 h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
      >
        {" "}
        Create templates
      </Link>
    </li>
  </ul>
</li>; */}



{/* <li>
  <a
    onClick={(e) => handleSubMenu(e)}
    href="#"
    className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
  >
    <span className="inline-flex justify-center items-center ml-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-collection"
        viewBox="0 0 16 16"
      >
        {" "}
        <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13z" />{" "}
      </svg>
    </span>
    <span className="ml-2 text-sm tracking-wide truncate">Poll themes</span>
    <span className="hidden md:block px-2 py-0.5 ml-auto text-xs font-medium tracking-wide">
      <svg
        className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 false"
        viewBox="0 0 12 12"
      >
        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"></path>
      </svg>
    </span>
  </a>

  <ul className="hidden flex flex-col py-2 space-y-1">
    <li className="">
      <a
        href="#"
        className="relative flex flex-row items-center px-12 h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
      >
        {" "}
        Themes
      </a>
    </li>
    <li className="">
      <Link
        to={"/creator/themes/create"}
        className="relative flex flex-row items-center px-12 h-11 focus:outline-none hover:bg-[#ffff00] text-white-600 hover:text-black border-l-4 border-transparent pr-6"
      >
        {" "}
        Create theme
      </Link>
    </li>
  </ul>
</li>; */}