import React from "react";
import { Link } from "react-router-dom";

const StepByStepGuide = () => {
  return (
    <div className="bg-neutral-900 min-h-screen text-neutral-100">
      {/* Main Content */}
      <main className="container mx-auto px-4 py-12 max-w-4xl">
        <article className="prose prose-invert max-w-none">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <span className="text-[#ff0] font-semibold">Tutorial</span>
            <h1 className="text-4xl md:text-5xl font-bold mt-2 mb-4">
              Step-by-Step Guide to Using PollSage
            </h1>
            <p className="text-xl text-neutral-300 max-w-2xl mx-auto">
              Learn how to create, share, and analyze polls in minutes with our
              comprehensive guide.
            </p>
          </div>

          {/* Steps Container */}
          <div className="space-y-20">
            {/* Step 1 */}
            <section className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/3 flex-shrink-0">
                <div className="bg-[#ff0]/10 p-4 rounded-lg inline-block mb-4">
                  <span className="text-3xl font-bold text-[#ff0]">1</span>
                </div>
                <h2 className="text-2xl font-bold mb-2">Sign Up or Log In</h2>
                <p className="text-neutral-400">
                  Get started with your PollSage account
                </p>
              </div>
              <div className="md:w-2/3 bg-neutral-800 p-8 rounded-xl border border-neutral-700">
                <div className="mb-6">
                  <h3 className="text-lg font-semibold text-[#ff0] mb-2">
                    Option A: Email Signup
                  </h3>
                  <ol className="list-decimal pl-5 space-y-3">
                    <li>Click "Sign Up" in the top-right corner</li>
                    <li>Enter your email and create a password</li>
                    <li>Verify your email via the confirmation link</li>
                  </ol>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-[#ff0] mb-2">
                    Option B: Social Login
                  </h3>
                  <ol className="list-decimal pl-5 space-y-3">
                    <li>Select Google, Microsoft, or Apple login</li>
                    <li>Authorize PollSage to access basic profile info</li>
                    <li>You'll be redirected to your dashboard</li>
                  </ol>
                </div>
                <div className="mt-6 p-4 bg-neutral-700 rounded-lg">
                  <span className="font-semibold text-[#ff0]">Pro Tip:</span>{" "}
                  Use SSO for faster access if your organization supports it.
                </div>
              </div>
            </section>

            {/* Step 2 */}
            <section className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/3 flex-shrink-0">
                <div className="bg-[#ff0]/10 p-4 rounded-lg inline-block mb-4">
                  <span className="text-3xl font-bold text-[#ff0]">2</span>
                </div>
                <h2 className="text-2xl font-bold mb-2">Create a New Poll</h2>
                <p className="text-neutral-400">
                  Build your first interactive poll
                </p>
              </div>
              <div className="md:w-2/3 bg-neutral-800 p-8 rounded-xl border border-neutral-700">
                <div className="space-y-6">
                  <div className="p-4 bg-neutral-700 rounded-lg">
                    <h3 className="font-semibold mb-2">Basic Information</h3>
                    <ul className="list-disc pl-5 space-y-2">
                      <li>Click "New Poll" on your dashboard</li>
                      <li>Enter a clear, concise question</li>
                      <li>Add at least 2 answer options</li>
                    </ul>
                  </div>

                  <div className="p-4 bg-neutral-700 rounded-lg">
                    <h3 className="font-semibold mb-2">Advanced Options</h3>
                    <div className="grid md:grid-cols-2 gap-4">
                      <div>
                        <h4 className="text-[#ff0] text-sm mb-1">Poll Type</h4>
                        <ul className="space-y-1">
                          <li>• Single-choice</li>
                          <li>• Multiple-choice</li>
                          <li>• Ranking poll</li>
                        </ul>
                      </div>
                      <div>
                        <h4 className="text-[#ff0] text-sm mb-1">Settings</h4>
                        <ul className="space-y-1">
                          <li>• Anonymous voting</li>
                          <li>• Results visibility</li>
                          <li>• Close date</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-3">
                    <span className="bg-[#ff0]/10 text-[#ff0] px-3 py-1 rounded-full text-sm">
                      Drag-and-drop reordering
                    </span>
                    <span className="bg-[#ff0]/10 text-[#ff0] px-3 py-1 rounded-full text-sm">
                      Image options
                    </span>
                    <span className="bg-[#ff0]/10 text-[#ff0] px-3 py-1 rounded-full text-sm">
                      Custom themes
                    </span>
                  </div>
                </div>
              </div>
            </section>

            {/* Step 3 */}
            <section className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/3 flex-shrink-0">
                <div className="bg-[#ff0]/10 p-4 rounded-lg inline-block mb-4">
                  <span className="text-3xl font-bold text-[#ff0]">3</span>
                </div>
                <h2 className="text-2xl font-bold mb-2">Customize Design</h2>
                <p className="text-neutral-400">
                  Match your poll to your brand
                </p>
              </div>
              <div className="md:w-2/3 bg-neutral-800 p-8 rounded-xl border border-neutral-700">
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <h3 className="text-lg font-semibold text-[#ff0] mb-3">
                      Theme Options
                    </h3>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <div className="bg-[#ff0] w-4 h-4 rounded-full mt-1 mr-3"></div>
                        <div>
                          <h4 className="font-medium">Color Scheme</h4>
                          <p className="text-sm text-neutral-400">
                            Choose from presets or custom HEX codes
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <div className="bg-[#ff0] w-4 h-4 rounded-full mt-1 mr-3"></div>
                        <div>
                          <h4 className="font-medium">Font Style</h4>
                          <p className="text-sm text-neutral-400">
                            Select from 10+ modern fonts
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-[#ff0] mb-3">
                      Branding
                    </h3>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <div className="bg-[#ff0] w-4 h-4 rounded-full mt-1 mr-3"></div>
                        <div>
                          <h4 className="font-medium">Logo Upload</h4>
                          <p className="text-sm text-neutral-400">
                            Add your company logo (PNG/SVG)
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <div className="bg-[#ff0] w-4 h-4 rounded-full mt-1 mr-3"></div>
                        <div>
                          <h4 className="font-medium">Custom CSS</h4>
                          <p className="text-sm text-neutral-400">
                            For advanced styling (Pro feature)
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-6 p-4 bg-neutral-700 rounded-lg flex items-start">
                  <span className="text-[#ff0] mr-3">💡</span>
                  <p>
                    Use our template gallery to quickly apply professional
                    designs for events, education, or marketing polls.
                  </p>
                </div>
              </div>
            </section>

            {/* Step 4 */}
            <section className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/3 flex-shrink-0">
                <div className="bg-[#ff0]/10 p-4 rounded-lg inline-block mb-4">
                  <span className="text-3xl font-bold text-[#ff0]">4</span>
                </div>
                <h2 className="text-2xl font-bold mb-2">Share Your Poll</h2>
                <p className="text-neutral-400">Distribute to your audience</p>
              </div>
              <div className="md:w-2/3 bg-neutral-800 p-8 rounded-xl border border-neutral-700">
                <div className="grid md:grid-cols-3 gap-4 mb-6">
                  <div className="bg-neutral-700 p-4 rounded-lg">
                    <h3 className="font-semibold text-[#ff0] mb-2">
                      Direct Link
                    </h3>
                    <p className="text-sm mb-3">Copy-paste anywhere</p>
                    <button className="bg-[#ff0] hover:bg-[#ff0]/90 text-neutral-900 text-sm font-bold py-1 px-3 rounded w-full transition">
                      Copy Link
                    </button>
                  </div>
                  <div className="bg-neutral-700 p-4 rounded-lg">
                    <h3 className="font-semibold text-[#ff0] mb-2">
                      Embed Code
                    </h3>
                    <p className="text-sm mb-3">For websites/blogs</p>
                    <button className="bg-[#ff0] hover:bg-[#ff0]/90 text-neutral-900 text-sm font-bold py-1 px-3 rounded w-full transition">
                      Get Embed
                    </button>
                  </div>
                  <div className="bg-neutral-700 p-4 rounded-lg">
                    <h3 className="font-semibold text-[#ff0] mb-2">
                      Social Media
                    </h3>
                    <p className="text-sm mb-3">One-click sharing</p>
                    <div className="flex space-x-2">
                      <button className="bg-blue-500 hover:bg-blue-600 text-white p-1 rounded">
                        <svg
                          className="w-4 h-4"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                        </svg>
                      </button>
                      <button className="bg-blue-600 hover:bg-blue-700 text-white p-1 rounded">
                        <svg
                          className="w-4 h-4"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                      <button className="bg-gray-800 hover:bg-gray-900 text-white p-1 rounded">
                        <svg
                          className="w-4 h-4"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="p-4 bg-neutral-700 rounded-lg">
                  <h3 className="font-semibold mb-2">Advanced Distribution</h3>
                  <ul className="list-disc pl-5 space-y-2">
                    <li>Email campaigns (Mailchimp integration)</li>
                    <li>Slack/Microsoft Teams bots</li>
                    <li>QR code download for print materials</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Step 5 */}
            <section className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/3 flex-shrink-0">
                <div className="bg-[#ff0]/10 p-4 rounded-lg inline-block mb-4">
                  <span className="text-3xl font-bold text-[#ff0]">5</span>
                </div>
                <h2 className="text-2xl font-bold mb-2">Analyze Results</h2>
                <p className="text-neutral-400">Gain insights from responses</p>
              </div>
              <div className="md:w-2/3 bg-neutral-800 p-8 rounded-xl border border-neutral-700">
                <div className="grid md:grid-cols-2 gap-6 mb-6">
                  <div>
                    <h3 className="text-lg font-semibold text-[#ff0] mb-3">
                      Real-Time Dashboard
                    </h3>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <div className="bg-[#ff0] w-4 h-4 rounded-full mt-1 mr-3"></div>
                        <div>
                          <h4 className="font-medium">Live Updates</h4>
                          <p className="text-sm text-neutral-400">
                            See votes as they come in
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <div className="bg-[#ff0] w-4 h-4 rounded-full mt-1 mr-3"></div>
                        <div>
                          <h4 className="font-medium">Demographic Filters</h4>
                          <p className="text-sm text-neutral-400">
                            Segment by location/device
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-[#ff0] mb-3">
                      Data Export
                    </h3>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <div className="bg-[#ff0] w-4 h-4 rounded-full mt-1 mr-3"></div>
                        <div>
                          <h4 className="font-medium">Formats</h4>
                          <p className="text-sm text-neutral-400">
                            CSV, Excel, PDF reports
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <div className="bg-[#ff0] w-4 h-4 rounded-full mt-1 mr-3"></div>
                        <div>
                          <h4 className="font-medium">API Access</h4>
                          <p className="text-sm text-neutral-400">
                            Integrate with BI tools
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="p-4 bg-neutral-700 rounded-lg">
                  <h3 className="font-semibold text-[#ff0] mb-2">
                    Visualization Tools
                  </h3>
                  <div className="flex flex-wrap gap-3">
                    <span className="bg-[#ff0]/10 text-[#ff0]"></span>
                  </div>
                </div>
              </div>
            </section>
            {/* Step 6 */}
            <section className="flex flex-col md:flex-row gap-8">
              <div className="md:w-1/3 flex-shrink-0">
                <div className="bg-[#ff0]/10 p-4 rounded-lg inline-block mb-4">
                  <span className="text-3xl font-bold text-[#ff0]">6</span>
                </div>
                <h2 className="text-2xl font-bold mb-2">Customize & Share</h2>
                <p className="text-neutral-400">
                  Make your survey more engaging
                </p>
              </div>
              <div className="md:w-2/3 bg-neutral-800 p-8 rounded-xl border border-neutral-700">
                <div className="grid md:grid-cols-2 gap-6 mb-6">
                  <div>
                    <h3 className="text-lg font-semibold text-[#ff0] mb-3">
                      Customize
                    </h3>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <div className="bg-[#ff0] w-4 h-4 rounded-full mt-1 mr-3"></div>
                        <div>
                          <h4 className="font-medium">Question Types</h4>
                          <p className="text-sm text-neutral-400">
                            Multiple choice, checkboxes, text fields
                          </p>
                        </div>
                      </li>
                      <li className="flex items-start">
                        <div className="bg-[#ff0] w-4 h-4 rounded-full mt-1 mr-3"></div>
                        <div>
                          <h4 className="font-medium">Response Styles</h4>
                          <p className="text-sm text-neutral-400">
                            Bar charts, pie charts, heat maps
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </article>
      </main>
    </div>
  );
};
export default StepByStepGuide;