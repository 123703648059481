import React from "react";
import { WEBSITE_NAME } from "../global/constants";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-neutral-900 border-t border-neutral-800 py-12">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-6 md:mb-0">
            <Link to="/" className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="60"
                viewBox="0 0 200 60"
                fill="none"
              >
                <path
                  d="M10 40V20C10 18.8954 10.8954 18 12 18H18C19.1046 18 20 18.8954 20 20V40C20 41.1046 19.1046 42 18 42H12C10.8954 42 10 41.1046 10 40Z"
                  fill="#FFD700"
                />
                <path
                  d="M30 40V15C30 13.8954 30.8954 13 32 13H38C39.1046 13 40 13.8954 40 15V40C40 41.1046 39.1046 42 38 42H32C30.8954 42 30 41.1046 30 40Z"
                  fill="#FFA500"
                />
                <path
                  d="M50 40V25C50 23.8954 50.8954 23 52 23H58C59.1046 23 60 23.8954 60 25V40C60 41.1046 59.1046 42 58 42H52C50.8954 42 50 41.1046 50 40Z"
                  fill="#FF6347"
                />

                <text
                  x="70"
                  y="40"
                  fontFamily="Arial, sans-serif"
                  fontSize="20"
                  fontWeight="bold"
                  fill="#FFFFFF"
                  style={{ letterSpacing: "2px" }}
                >
                  Pollsage
                </text>

                <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
                  <feGaussianBlur
                    in="SourceAlpha"
                    stdDeviation="2"
                    result="blur"
                  />
                  <feOffset in="blur" dx="0" dy="0" result="offsetBlur" />
                  <feMerge>
                    <feMergeNode in="offsetBlur" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
                <g filter="url(#glow)">
                  <path
                    d="M10 40V20C10 18.8954 10.8954 18 12 18H18C19.1046 18 20 18.8954 20 20V40C20 41.1046 19.1046 42 18 42H12C10.8954 42 10 41.1046 10 40Z"
                    fill="#FFD700"
                  />
                  <path
                    d="M30 40V15C30 13.8954 30.8954 13 32 13H38C39.1046 13 40 13.8954 40 15V40C40 41.1046 39.1046 42 38 42H32C30.8954 42 30 41.1046 30 40Z"
                    fill="#FFA500"
                  />
                  <path
                    d="M50 40V25C50 23.8954 50.8954 23 52 23H58C59.1046 23 60 23.8954 60 25V40C60 41.1046 59.1046 42 58 42H52C50.8954 42 50 41.1046 50 40Z"
                    fill="#FF6347"
                  />
                </g>
              </svg>
            </Link>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-sm font-semibold text-white uppercase tracking-wider mb-4">
                Product
              </h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/features"
                    className="text-neutral-400 hover:text-white"
                  >
                    Features
                  </Link>
                </li>
                <li>
                  <Link
                    to="/pricing"
                    className="text-neutral-400 hover:text-white"
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/integrations"
                    className="text-neutral-400 hover:text-white"
                  >
                    Integrations
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-white uppercase tracking-wider mb-4">
                Resources
              </h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/blogs"
                    className="text-neutral-400 hover:text-white"
                  >
                    Blogs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/guides"
                    className="text-neutral-400 hover:text-white"
                  >
                    Guides
                  </Link>
                </li>
                <li>
                  <Link
                    to="/support"
                    className="text-neutral-400 hover:text-white"
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-white uppercase tracking-wider mb-4">
                Company
              </h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/about"
                    className="text-neutral-400 hover:text-white"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/careers"
                    className="text-neutral-400 hover:text-white"
                  >
                    Careers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    className="text-neutral-400 hover:text-white"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-white uppercase tracking-wider mb-4">
                Legal
              </h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/privacy-policy"
                    className="text-neutral-400 hover:text-white"
                  >
                    Privacy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms"
                    className="text-neutral-400 hover:text-white"
                  >
                    Terms
                  </Link>
                </li>
                <li>
                  <Link
                    to="/security"
                    className="text-neutral-400 hover:text-white"
                  >
                    Security
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-neutral-800 flex flex-col md:flex-row justify-between items-center">
          <p className="text-neutral-500 text-sm">
            © 2025 PollSage. All rights reserved.
          </p>
          <div className="flex space-x-6 mt-4 md:mt-0">
            <Link to="#" className="text-neutral-400 hover:text-white">
              <span className="sr-only">Twitter</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </Link>
            <Link to="#" className="text-neutral-400 hover:text-white">
              <span className="sr-only">GitHub</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
